import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

// Connects to data-controller="workout-socket"
export default class extends Controller {
  static values = {uid: Number, wid: Number}

  connect() {
    console.log('workout socket connected')
    this.channel = createConsumer().subscriptions.create(
        { channel: "WorkoutChannel", workout_id: this.widValue, user_id: this.uidValue },
        { received: (data) => this.renderPartial(data) }
    );
  }

  renderPartial(data) {
    console.log(data)
  }

  disconnect() {
    this.channel.unsubscribe();
  }
}
