/**
 * Created by mohitbhansali on 10/06/18.
 */

export default class MusicProvider {

    static sharedProvider() {
        if (!MusicProvider.instance) {
            MusicProvider.instance = new MusicProvider();
        }
        return MusicProvider.instance;
    }

    configure() {
        window.MusicKit.configure({
            developerToken: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkRHQlo1UVc4UEsifQ.eyJpYXQiOjE3MTg3Mjg4MjEsImV4cCI6MTczNDI4MDgyMSwiaXNzIjoiUUo4WVU3WVM4ViJ9.dNmF-UmvgWnmAVpyJJ219fy7G2Mg374faF0IbPc2UX7HOI79n2KOTcXBOaQSqDbOGy-TZZ7Hp9WrThjMXau3FA',
            app: {
                name: 'damusic',
                build: '0.0.1'
            }
        });
    }

    getMusicInstance() {
        return window.MusicKit.getInstance();
    }
}
